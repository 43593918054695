import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { SeoService, SessionService } from './_services';
import { QueryString } from './_utilities/querystring';
import { AuthGuardService } from './_utilities/auth.guard.service';
import { AuthCheckGuardService } from './auth-check/auth.check.guard.service';
import { AppFooterComponent } from './app-footer';
import { AppHeaderComponent } from './app-header';
import { LoginComponent } from './login/login.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { NotificationModule } from './notification/notification.module';

import '../../bower_components/velocity/velocity.js';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  Title,
  AppState,
  SessionService,
  QueryString,
  AuthGuardService,
  AuthCheckGuardService,
  SeoService
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
    LoginComponent,
    BarcodeComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NotificationModule
  ],
  providers: [
    APP_PROVIDERS,
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-NLLXZN4'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
