import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AppState } from '../../app.service';

@Injectable()
export class SeoService {
  public company: string = 'ProQuest';

  private _baseTitle: string = this.company + ' Digital Sanborn Maps';

  constructor (
    private _titleService: Title,
    private _meta: Meta,
    private _appState: AppState
  ) {}

  public setTitle (title: string): void {
    let baseTitle = this._baseTitle;

    baseTitle += ' ~ ';
    title = title.replace('&shy;', '');

    this._titleService.setTitle(baseTitle + title);
    this._appState.set({gaPageTitle : baseTitle + title});
  }

  public setMetaTag (name: string, content: string): void {
    const metaTag = this._meta.getTag(name);

    if (metaTag) {
      this._meta.removeTag(name);
    }

    this._meta.addTag({ name, content });

  }
}
