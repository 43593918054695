import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class StatesService {
  private _url: string = '/svc/sanborn/states';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public get (): Promise<any> {
    if (this._appState.get('states')) {
      return of({ SanbornStatesResponse: { SanbornStateResponse: this._appState.get('states'), error: false }}).toPromise();
    }

    return this._http.get(this._url, { params: { accountid: this._appState.get('accountId') }})
      .pipe(
        map((response: object) => {
          if (response['SanbornStatesResponse'] && response['SanbornStatesResponse']['SanbornStateResponse']) {
            this._appState.set({ states: response['SanbornStatesResponse']['SanbornStateResponse'] });
          }

          return response;
        })
      ).toPromise();
  }
}
