import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DatesService {
  private _url: string = '/svc/sanborn/dates';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public get (cityId: string, dateId: string): Promise<any> {
    const dates = this._appState.get('dates') || {};

    if (dates[cityId] && dateId  && dates[cityId][dateId]) {
      return of({ SanbornDatesResponse: { SanbornDateResponse: dates[cityId][dateId], error: 'false' }  }).toPromise();
    }

    return this._http.get(this._url, { params: { id: cityId, accountid:this._appState.get('accountId') } })
      .pipe(
        map((response: object) => {


          if(!dates[cityId] && response['SanbornDatesResponse']
            && response['SanbornDatesResponse']['SanbornDateResponse']) {

            if (!dates[cityId]) {
              dates[cityId] = {};
            }

            if (!dateId) {
              dateId = Array.isArray(response['SanbornDatesResponse']['SanbornDateResponse']) ?
                response['SanbornDatesResponse']['SanbornDateResponse'][0]['id'] :
                response['SanbornDatesResponse']['SanbornDateResponse']['id'];
            }

            dates[cityId][dateId] = response['SanbornDatesResponse']['SanbornDateResponse'];
            this._appState.set({ dates });
          }

          return response;
        })
      ).toPromise();
  }
}
