
<header [ngClass]="{ 'noNav': !isAuthenticated }">
    <section class="branding" role="banner" *ngIf="branding.displayBanner">
      <a [href]="branding.bannerLink" class="brandLink" target="_school">
        <img [src]="branding.bannerLogoPath" [alt]="branding.bannerText">
      </a>
      <a [href]="branding.bannerResourceLink" target="_blank">{{branding.bannerResourceText}}</a>
    </section>
    <h1 class="container-appTitle">
        <a [routerLink]="['about']">
            <div class="container-logoImg diagonal">
                <span class="uxf-icon uxf-compass"></span>
            </div>
            <div class="container-logoText">
                <div class="pq-logo" title="ProQuest"></div>
                Digital Sanborn Maps<span>, 1867&ndash;1970</span>
            </div>
        </a>
    </h1>

    <div class="navbar-toggler" *ngIf="isAuthenticated">
      <button class="collapsed" type="button" data-toggle="collapse" [attr.aria-expanded]="isMenuOpen" aria-label="Toggle navigation" (click)="handleMobileMenuToggle($event)">
        <span class="uxf-icon uxf-menu"></span>
      </button>
    </div>


    <nav class="container-menu sub-menu" [ngClass]="{ 'show': isMenuOpen }">
        <ul>
          <li *ngIf="isAuthenticated">
            <button (click)="handleLogOutRequest()">
              <span *ngIf="!isWorking">Log out</span>
              <div class="lds-dual-ring" *ngIf="isWorking"></div>
            </button>
          </li>
          <li><a href="https://www.proquest.com/go/sanbornmaps-faqs" class="link-ext" target="_blank"><span class="uxf-icon uxf-help-circle" title="Help"></span><span class="txt-help">Help</span></a></li>
        </ul>
    </nav>

    <nav [ngClass]="{ 'show': isMenuOpen }" *ngIf="isAuthenticated" class="main-menu">
        <ul>
            <li><a routerLink="/about" routerLinkActive="active-link">About</a></li>
            <li><a routerLink="/browse_maps" routerLinkActive="active-link">Browse</a></li>
            <li class="li-mapsLegend"><a href="../../assets/content/sanborn_bwkey.pdf" class="link-ext" target="_legend">Maps Legend</a></li>
            <li *ngIf="browseMapsCommands" class="li-browseMaps"><a [routerLink]="browseMapsCommands" [queryParams]="browseMapsQueryParams">{{ browseMapsCommandsLabel }}</a></li>
        </ul>
    </nav>
</header>
