import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppState } from '../app.service';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { SessionService } from '../_services';

@Injectable ()
export class AuthCheckGuardService implements CanActivate {

  constructor (
    public appState: AppState,
    private _sessionService: SessionService,
    private _router: Router
  ) {
  }

  static removeQueryStringParameter (key: string, queryParamLocation: string): string {
    let location = queryParamLocation;
    const regex = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i');

    if (location.match(regex)) {
      // REMOVE KEY AND VALUE
      location = location.replace(regex, '$1');

      // REMOVE TRAILING ? OR &
      location = location.replace(/([?&])$/, '');
    }
    return location;
  }

  public canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | Promise<boolean> | boolean {
    return this._sessionService.isAuthenticated().pipe(
      first(),
      map( (isLoggedIn: boolean) => {
        const status = Number.parseInt(this.appState.get('status'));
        const profile = this.appState.get('current_profile') || '';
        const commands = [];

        // do proxy redirect if required
        const defaultProxyLoginUrl = sessionStorage.getItem('defaultProxyLoginUrl');
        const url = decodeURIComponent(window.location.href);

        if (( status === 504 ) || ( status === 503 )) {
          if (profile) {
            commands.push(profile);
          }

          commands.push('error');
          this._router.navigate(commands, {replaceUrl: true});
          return true;

        } else if (defaultProxyLoginUrl && !isLoggedIn) {
          const barcodePath = '/barcode?';
          const loginPath = '/login?';

          if (( defaultProxyLoginUrl.includes(barcodePath) || defaultProxyLoginUrl.includes(loginPath) )
            && defaultProxyLoginUrl.toLowerCase().includes('accountid')) {

            if (defaultProxyLoginUrl.includes(barcodePath) && !url.includes(barcodePath)) {
              this._updateAuthLoginUrl('barcode', defaultProxyLoginUrl, profile, url);
            } else if (defaultProxyLoginUrl.includes(loginPath) && !url.includes(loginPath)) {
              this._updateAuthLoginUrl('login', defaultProxyLoginUrl, profile, url);
            }

          } else if (defaultProxyLoginUrl.includes('?')) {
            // In PAM if default auth url is http://ezproxy.qa.proquest.com?url for an accountid
            // And if we access = http://localhost:3000/elibrary/document/3521734?accountid=93106
            // document.location.href is going to be the same as above
            let uri = decodeURI(window.location.href);
            // However if we access = http://localhost:3000/elibrary/?accountid=93106
            // this url will be navigated through no-content-guard. Hence the url will have location params.
            const urlParams = decodeURIComponent(window.location.search);

            if (urlParams && urlParams.includes('location=/')) {
              const locationParams = urlParams.substr(urlParams.indexOf('location=/') + 10);
              uri = decodeURI(window.location.href) + locationParams;
            }

            const newQueryParams = AuthCheckGuardService.removeQueryStringParameter('searchId', uri);

            window.location.replace(defaultProxyLoginUrl  + newQueryParams);
          } else {
            // In PAM if default proxy url is http://fpizzo1.wixsite.com/home
            window.location.replace(defaultProxyLoginUrl);
          }
        } else if (isLoggedIn) {
          if (this.appState.get('accountId')) {
            Cookie.set('accountId', this.appState.get('accountId'), null, '/');
          }

          this._router.navigate(['/', 'about']);

        } else if (!isLoggedIn) {
          const commands = ['login'];

          if (this.appState.get('current_profile')) {
            commands.splice(0, 0, this.appState.get('current_profile'));
          }

          this._router.navigate(commands, { replaceUrl: true, queryParams: new URLSearchParams(window.location.search) });
          return true;
        } else {
          return true;
        }
      })
    );
  }

  private _updateAuthLoginUrl (type: string, defaultProxyLoginUrl: string, profile: string, url: string): void {
    // In PAM if default auth url is /barcode?accountid=92926&groupid=1234411
    // or /login?accountid=189737
    const route = '/' + type + '?';
    const fromStr = defaultProxyLoginUrl.indexOf(route);
    let newQueryParams = AuthCheckGuardService.removeQueryStringParameter('searchId', decodeURI(url));
    newQueryParams =  AuthCheckGuardService.removeQueryStringParameter('accountid', newQueryParams);
    newQueryParams =  AuthCheckGuardService.removeQueryStringParameter('groupid', newQueryParams);
    newQueryParams = '&' + newQueryParams.split('?')[1] || '';
    const newQueryParamsObject = new URLSearchParams(newQueryParams);

    defaultProxyLoginUrl = defaultProxyLoginUrl.substring(fromStr);
    this._router.navigateByUrl(defaultProxyLoginUrl + '?' + newQueryParamsObject.toString(), { replaceUrl: true });
  }
}
