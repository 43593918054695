import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pq-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./_app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  public year: string = '';

  public ngOnInit (): void {
    this.setCopyYear();
  }

  public setCopyYear (): void {
    const date = new Date();
    const year = date.getFullYear();

    this.year = year.toString();
  }
}
