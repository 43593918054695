import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class TileService {
  private _url: string = '/svc/sanborn/tile';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public createEndPoint (level: number = 0, x: number = 0, y: number = 0, settings: object): string | undefined {
    let url = this._url + '?';

    for (const prop in settings) {
      if (settings.hasOwnProperty(prop)) {
        url += prop + '=' + settings[prop] + '&';
      }
    }

    url += 'zoomlevel=' + level.toString() + '&';
    url += 'tilex=' + x.toString() + '&';
    url += 'tiley=' + y.toString();

    return url;
  }
}
