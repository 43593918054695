import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CitiesService {
  private _url: string = '/svc/sanborn/cities';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public get (stateId: string): Promise<any> {
    const cities = this._appState.get('stateCities') || {};

    if (cities[stateId]) {
      return of({ SanbornCitiesResponse: { SanbornCityResponse: cities[stateId], error: false }}).toPromise();
    }

    return this._http.get(this._url, { params: { id: stateId, accountid: this._appState.get('accountId')} })
      .pipe(
        map((response: object) => {

          // check to see if cities are stored in memory and if not add them
          if (!cities[stateId] &&
            response['SanbornCitiesResponse'] &&
            response['SanbornCitiesResponse']['SanbornCityResponse']) {

            cities[stateId] = response['SanbornCitiesResponse']['SanbornCityResponse'];
            this._appState.set({ stateCities: cities });
          }

          return response;
        })
      ).toPromise();
  }
}
