import { Component, OnInit } from '@angular/core';
import { SeoService, SessionService } from '../_services';
import { User } from './user';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppState } from '../app.service';
import text from '../../assets/data/text.json'
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'pq-login',
  templateUrl: './login.component.html',
  styleUrls: ['./_login.component.scss']
})
export class LoginComponent implements OnInit {

  public model: User = new User('', '');
  public errorText: string = '';
  public hasSubmitted: boolean =  false;
  public hasError: boolean = false;
  public isLoading: boolean = true;

  private _redirectLocation: string = '';

  private _errorMessages: object = {
    incomplete: text.userpass.incomplete,
    service: text.userpass.service,
    incorrect: text.userpass.incorrect
  };

  constructor(
    private _seoSvc: SeoService,
    private _sessionSvc: SessionService,
    private _router: Router,
    private _appState: AppState,
    private _route: ActivatedRoute
  ) {
    this._seoSvc.setTitle('Log in');
    this._route.queryParams.subscribe((queryParam: any) => {
      this._redirectLocation = queryParam['location'];
    });
  }

  public ngOnInit (): void {
    this._route.queryParamMap
      .pipe(
        switchMap((queryParams: ParamMap) => {
          const username = (queryParams.get('u') || queryParams.get('username')) as string;
          const password = (queryParams.get('p') || queryParams.get('password')) as string;

          if (username && password) {
            return this._sessionSvc.doLogIn({ username: username.trim(), password: password.trim() });
          }

          return of({ showForm: true });
        })
      )
      .subscribe((response: object) => {

        if (response['showForm']) {
          this.isLoading = false;
        } else {
          this._handleSessionResponse(response);
        }

      });
  }

  public onSubmit (): void {
    const username = this.model.username ? this.model.username.trim() : '';
    const password = this.model.password ? this.model.password.trim() : '';

    if (username && password) {
      this.hasSubmitted = true;
      this._resetLocalSessionData();

      const request = this._sessionSvc.doLogIn(this.model).toPromise();

      request.then((resp:  object) => {

        if (resp['error']) {
          this._handleError(resp['error'], this._errorMessages['service']);
        } else {
          this._handleSessionResponse(resp);
        }
      });

      request.catch((err: any) => {
        this._handleError(err, this._errorMessages['service']);
      });

    } else {
      this._handleError(null, this._errorMessages['incorrect']);
    }
  }

  private _handleSessionResponse (session: object): void {
    if (!session['error'] && session['sessionRoles'].indexOf('unauthenticated') > -1) {
      this._handleError(null, this._errorMessages['incorrect']);
      this.model.password = '';
      this.isLoading = false;
    } else if (session['error']['errors']) {
      this._handleError(session['errors'], this._errorMessages['service']);
      this.isLoading = false;
    } else {
      this._handleSuccessResp();
    }
  }

  private _handleSuccessResp (): void {
    this.hasSubmitted = false;
    this.hasError = false;

    let pathArray = ['/', 'about'];

    if (this._redirectLocation) {
      pathArray = this._redirectLocation.split('/');
    }

    this._router.navigate(pathArray, { replaceUrl: true });
  }

  private _handleError (err?: any, type?: string): void {
    this.hasSubmitted = false;
    this.hasError = true;
    this.errorText = type;
  }

  private _resetLocalSessionData (): void {
    sessionStorage.clear();
    Cookie.delete('session');
    Cookie.delete('accountId');
    this._appState.clear();
  }
}
