import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './_utilities/auth.guard.service';
import { AuthCheckGuardService } from './auth-check/auth.check.guard.service';
import { BarcodeComponent } from './barcode/barcode.component';

export const ROUTES: Route[] = [
  { path: 'login', component: LoginComponent },
  { path: 'barcode', component: BarcodeComponent },
  { path: 'embedded/:token', loadChildren: () => import('./auth-check/auth.check.module').then(x => x.AuthCheckModule), canActivate: [ AuthCheckGuardService ] },
  { path: ':profile/embedded/:token', loadChildren: () => import('./auth-check/auth.check.module').then(x => x.AuthCheckModule), canActivate: [ AuthCheckGuardService ] },
  { path: 'ip', loadChildren: () => import('./auth-check/auth.check.module').then(x => x.AuthCheckModule), canActivate: [ AuthCheckGuardService ] },
  { path: 'refurl', loadChildren: () => import('./auth-check/auth.check.module').then(x => x.AuthCheckModule), canActivate: [ AuthCheckGuardService ] },
  { path: 'geoauth', loadChildren: () => import('./auth-check/auth.check.module').then(x => x.AuthCheckModule), canActivate: [ AuthCheckGuardService ] },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'browse_maps',
    loadChildren: () => import('./browse/browse.module').then((m) => m.BrowseModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: '',
    redirectTo: 'about',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./no-content/no-content.module').then((m) => m.NoContentModule)
  }
];
