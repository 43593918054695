<footer>
    <div>
        <div class="container-legalText">
            <p>
                <span>NON-COMMERCIAL USE ONLY </span>Digital Sanborn Maps are not suitable for legal or regulatory purposes.
                Digital Sanborn Maps may not be downloaded, clipped, printed, resold, retained or used for commercial or
                business research purposes, including but not limited to environmental, property or other land use evaluations.
                Digital Sanborn Maps are reproduced and distributed under license from Environmental Data Resources, Inc.
                For information on how to obtain maps for commercial and business research purposes, go to
                <a href="https://www.edrnet.com/sanborn" target="_blank">www.edrnet.com/sanborn</a>.
            </p>
        </div>
        <div class="container-footerMain">
            <div><span class="footer-logo" role="img" aria-label="Logo: ProQuest - Part of Clarivate"></span></div>
            <nav>
                <ul>
                    <li><a href="https://www.proquest.com/go/sanbornsupportcontact" target="_blank">Contact Us</a></li>
                    <li><a href="https://www.proquest.com/go/termsandconditions" target="_blank">Terms and Conditions</a></li>
                    <li><a href="https://www.proquest.com/go/privacy" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.proquest.com/go/cookie" target="_blank">Cookie Policy</a></li>
                    <li>
                      <!-- OneTrust Cookies Settings button start -->
                      <button class="ot-sdk-show-settings btn-action">Cookie Settings</button>
                      <!-- OneTrust Cookies Settings button end -->
                    </li>
                    <li><a href="https://support.proquest.com/articledetail?id=kA13r000000FyLSCA0" target="_blank">Accessibility</a></li>
                </ul>
                <p>All copyright in the Sanborn Maps are held by Environmental Data Resources, Inc. or its affiliates.
                    Sanborn Map Company logo used with permission.<br>Database and software copyright &copy; 2001&ndash;{{ year }}
                    ProQuest, LLC. All rights reserved.</p>
            </nav>
        </div>
    </div>
</footer>
