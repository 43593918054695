import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import XMLString from  '../../_utilities/xmlstring';
import { QueryString } from '../../_utilities/querystring';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { isBefore } from 'date-fns';

@Injectable()
export class SessionService {
  private _url: string = '/svc/sanborn/session';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
    private _queryString: QueryString
  ) {}

  public get (): Observable<object> {
    const data = this._createPostData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    console.log('get')

    return this._http.post(this._url, data, ({headers})).pipe(
      map((resp: Response) => {
        if (!resp['SessionResponse']) {
          throw new Error('Incorrect response');
        }

        this._createSessionData(resp['SessionResponse'] || {});

        return resp;
      })
    )
  }

  public logout (): Observable<object> {
    const data = this._createPostData();
    console.log(data);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('body', data);
    console.log("Make call to backend service");
    return this._http.delete(this._url, { headers }).pipe(
      map((resp) => {
        sessionStorage.clear();
        this._appState.clear();
        Cookie.delete('session');
        Cookie.delete('accountId');

        return of({ success: true });
      })
    );

  }

  public isAuthenticated (): Observable<boolean> {
    const sessionLimit = 12;
    const sessionObject = sessionStorage.getItem('sessionObject')
      ? JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    const sessionCreated = sessionObject['creationDate'];
    const sessionCreatedDate = sessionCreated ? new Date(sessionCreated) : null;
    const sessionId = sessionObject['sessionId'];
    const hasSessionId = typeof sessionId !== 'undefined' && sessionId !== null;
    let futureDate = sessionCreatedDate ? sessionCreatedDate.setDate(sessionCreatedDate.getMinutes() + sessionLimit) : new Date().setDate(new Date().getMinutes() + sessionLimit);

    this._setBranding(JSON.parse(sessionStorage.getItem('sessionObject')), sessionObject);

    // there is no session creation date OR
    // has creation date less then 15 minutes ago or does not have session ID OR
    // there is no session id
    if (sessionCreatedDate === null || (sessionCreatedDate && this._isDate(sessionCreatedDate) && isBefore(futureDate, new Date())) || !hasSessionId) {
      return this.get().pipe(
        switchMap((resp: Response) => {
          const session = resp;

          if (!session['SessionResponse']) {
            throw new Error('Incorrect response');
          }

          let roles = session['SessionResponse']['sessionRoles'] || [];
          if (!Array.isArray(roles)) {
            roles = [ roles ];
          }

          return of(roles.indexOf('authenticated') > -1);
        }),
        catchError(() => of(false))
      );
    } else {
      return of(hasSessionId);
    }
  }

  public doLogIn (data: object): Observable<object> {
    const headers = new HttpHeaders();
    const postData =  this._createPostData(data);

    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    return this._http.post(this._url, postData, ({headers})).pipe(
      map((resp) => {

        if (!resp['SessionResponse']) {
          throw new Error('Incorrect response');
        }

        this._createSessionData(resp['SessionResponse'] || {});

        return resp['SessionResponse'];
      }),
      catchError((err) => of({ sessionRoles: Array.isArray(err['sessionRoles']) ? err['sessionRoles'] : [err['sessionRoles']], error: err['error'] }))
    );
  }

  private _createSessionData (session: object): void {
    let roles = session['sessionRoles'] || [];
    if (!Array.isArray(roles)) {
      roles = [ roles ];
    }

    if (session[ 'accountId' ]) {
      Cookie.set('accountId', session[ 'accountId' ], null, '/');
      this._appState.set({ accountId: session[ 'accountId' ] });
    }

    if (roles.indexOf('authenticated') > -1) {
      const sessionObject = { sessionId: session[ 'sessionId' ], creationDate: new Date() };

      this._setBranding(session, sessionObject);

      sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
      Cookie.set('session', session[ 'sessionId' ], null, '/');
    }
  }

  private _setBranding (session: object, sessionObject: object): void {
    if (session && session['branding']) {
      this._appState.set({ branding: session['branding']});
      sessionObject['branding'] = session['branding'];
    }
  }

  private _createPostData (manualAuthData: object = {}): string {
    const pathname = window.location.pathname;
    const sessionObject = sessionStorage.getItem('sessionObject') ?
      JSON.parse(sessionStorage.getItem('sessionObject')) : {};

    const sessionId = sessionObject['sessionId'];
    const referrer = document.referrer;
    const accountId = this._queryString.getQueryString()['accountId'] || this._queryString.getQueryString()['accountid'];
    const barcode = manualAuthData['barcode'];
    const username = manualAuthData['username'];
    const groupId = manualAuthData['groupId'];

    let token = this._queryString.getQueryString()['token'] ;
    let data = '<SessionRequest>';
    const durableUrl = document.location.href;

    if (barcode) {
      data += XMLString.xmlwrap(barcode, 'barcode', false);

      if (groupId) {
        data += XMLString.xmlwrap(groupId, 'groupId', false);
      }

    } else if (username) {
      data += XMLString.xmlwrap(username, 'username', false)
        + XMLString.xmlwrap(manualAuthData[ 'password' ], 'password', false);

      // forced auth type based on url
    } else  if (/\/embedded\//.test(pathname)) {
      const pathData = window.location.pathname.split('/');
      token = pathData[pathData.length - 1]; // last value in the url path is the token
      data += XMLString.xmlwrap('TOKEN', 'strictTo', false);
      sessionStorage.setItem('authType', 'token');
    } else if (/\/ip/.test(pathname)) {
      data += XMLString.xmlwrap('IP', 'strictTo', false);
    } else if (/\/refurl/.test(pathname)) {
      data += XMLString.xmlwrap('REFERRER', 'strictTo', false);
      sessionStorage.setItem('authType', 'refurl');
    } else if (/\/geoauth/.test(pathname)) {
      data += XMLString.xmlwrap('GEOAUTH', 'strictTo', false);

    } else if (token) {
      data += XMLString.xmlwrap(token, 'token', false);
    }

    if (sessionId) {
      data += XMLString.xmlwrap(sessionId, 'sessionId', false);
    }

    if (accountId) {
      data += XMLString.xmlwrap(accountId, 'accountId', false);
      Cookie.set('accountId', accountId);
    }

    data += XMLString.xmlwrap(referrer, 'referrer', false);

    data += XMLString.xmlwrap(location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''), 'proxyHostPort', false);
    data += XMLString.xmlwrap(durableUrl, 'durableUrl', false);
    data += '</SessionRequest>';

    return data;
  }

  private _isDate (date): boolean {
    return date instanceof Date && !isNaN(date.valueOf());
  }
}
