import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { AppState } from './app.service';
import { safeSvg4Everybody } from './_utilities/safesvg4everybody';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare const Velocity: any;
declare var OneTrust: any;

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'pq-app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./_app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  private _error: boolean = false;

  constructor (
    private _router: Router,
    private _location: Location,
    private _appState: AppState,
    private _gtmService: GoogleTagManagerService
  ) {}

  public ngOnInit (): void {
    (async() => {
      while(!window.hasOwnProperty('OneTrust')) // define the condition as you like
        await new Promise(resolve => setTimeout(resolve, 1000));

      OneTrust.OnConsentChanged((e) => {
        this._updateTrackingByConsent(e.detail);
      });

    })();

    this._checkForOneTrustSettings(window['OnetrustActiveGroups'], 1);

    this._router.events.subscribe((routeEvent: any) => {
      const accountId = Cookie.get('accountId');

      this._error = false;

      if (routeEvent instanceof NavigationEnd) {
        if (accountId) {
          this._updateUrlWithAccountId(accountId);
        }
      }

      if (routeEvent instanceof NavigationError) {
        this._error = true;
      }
    });

    safeSvg4Everybody();
  }

  public onActivate (e: Event): void {
    const scrollToTop = (): void => {
      const smoothness = 8;
      const s = document.documentElement.scrollTop || document.body.scrollTop;
      let val = s - s / smoothness; if (val < smoothness) val = 0; window.scrollTo(0, val);

      if (val > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, val);
      }
    };

    scrollToTop();
  }

  public handleContentLink (e: Event): void {
    e.preventDefault();
    const contentEle = document.getElementById('content');
    Velocity(contentEle, 'scroll', { offset: '0', mobileHA: false });
    contentEle.focus();
  }

  private _checkForOneTrustSettings (settings, attempt): void {
    // try 10 time to wait for OneTrust to load or after the 10th time just load using all cookies
    if (typeof settings !== 'undefined' || attempt > 10) {
      this._loadGTM(settings || 'C0002,C0001,C0003,C0004');
      return;
    }

    setTimeout(() => {
      this._checkForOneTrustSettings(window['OnetrustActiveGroups'], attempt + 1);
    }, 100)
  }

  private _loadGTM (state): void {
    if (this._trackingScriptConsent(state)) {
      this._gtmService.addGtmToDom();
      const gtmScript = document.getElementById('GTMscript');
      if(gtmScript) {
        gtmScript.classList.add('optanon-category-C0002');
      }
    }
  }

  private _updateUrlWithAccountId (accountId: string): void {
    const urlData = this._location.path().split('?');
    const accountIdParam = 'accountid=' + accountId;
    const path = urlData[0] || '';
    let query = location['search'] ? location['search'].toLowerCase() : '';

    if (query && query.indexOf('accountid') === -1) {
      query += '&' + accountIdParam;
    } else if (!query) {
      query = '?' + accountIdParam;
    }

    this._appState.set({ accountId: accountId });
    this._location.replaceState(path, query + location['hash']);
  }

  /* Function to use cookie values to determine whether to load tracking script or not */
  private _trackingScriptConsent (state): boolean {
    return state && state.toString().indexOf('C0002') !== -1;
  }

  private _updateTrackingByConsent (consentState: any): void {
    const gtmScript = document.getElementById('GTMscript');
    const gtmIframe = document.getElementById('GTMiframe');
    const gaScript = this._getGAScript();
    // if the user revolts permission after giving permission
    if (gtmScript && !this._trackingScriptConsent(consentState)) {
      gtmScript.remove();
      gtmIframe.remove();
      if (gaScript) {
        gaScript.remove();
      }

      // if the user grants permission after not giving permission
    } else if (!gtmScript && this._trackingScriptConsent(consentState)) {
      this._gtmService.addGtmToDom();
    }
  }

  private _getGAScript (): HTMLElement {
    const gaSource = 'https://www.google-analytics.com/analytics.js';
    const theHead = document.getElementsByTagName('head')[0];
    const scripts = theHead.getElementsByTagName('script');
    let ga = undefined;

    for(let i = 0, total = scripts.length; i < total; i++) {
      let currentScript = scripts[i];
      if (currentScript.src === gaSource) {
        ga = currentScript;
      }
    }

    return ga;
  }
}
