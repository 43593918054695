import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class MapsService {
  private _url: string = '/svc/sanborn/maps';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public get (volumeId: string, stateAbbr: string,  cityId: string): Promise<object[]> {
    return this._http.get(this._url, { params: { accountid: this._appState.get('accountId'), id: volumeId, citynumber: cityId, stateabbr: stateAbbr  }})
      .pipe(
        switchMap ((response) => {
          let maps = response['SanbornMapsResponse'] && response['SanbornMapsResponse']['SanbornMapResponse']
            ? response['SanbornMapsResponse']['SanbornMapResponse']
            : [];

          if (!Array.isArray(maps)) {
            maps = [ maps ];
          }

          return of(maps);
        })
      )
      .toPromise();
  }
}
