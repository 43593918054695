<a href="#content" class="skip-main" (click)="handleContentLink($event)">Skip to content</a>
<pq-notification></pq-notification>
<!-- TrustArc consent banner [START] -->
<div id="consent_blackbar"></div>
<!-- TrustArc consent banner [END] -->
<pq-header></pq-header>
<main id="content" tabindex="-1">
  <div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</main>
<pq-footer></pq-footer>
