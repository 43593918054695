import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ImageService {
  private _baseUrl: string = '/svc/sanborn/';

  constructor (
    private _appState: AppState,
    private _http: HttpClient
  ) {}

  public getThumbnail (stateAbbr: string, reelId: string, cityNumber: string, imageId: string): Promise<any> {
    const endPoint = 'thumbnailmap';

    return this._http.get(this._baseUrl + endPoint, { responseType: 'blob',  params: { stateabbr: stateAbbr, reelid: reelId, citynumber: cityNumber, imageid: imageId, accountid:this._appState.get('accountId') } })
      .pipe(
        map((response: object) => {
          console.log(response);
          return response;
        })
      ).toPromise();
  }

  public createThumbnailImageUrl (stateAbbr: string, reelId: string, cityNumber: string, imageId: string): string {
    const endPoint = 'thumbnailmap';

    return this._baseUrl + endPoint + '?stateabbr=' + stateAbbr + '&reelid=' + reelId + '&citynumber=' + cityNumber + '&imageid=' + imageId;
  }

  public get (stateAbbr: string, reelId: string, cityNumber: string, imageId: string): Promise<string> {
    const endPoint = 'fullmap';

    return this._http.get(this._baseUrl + endPoint, { responseType: 'blob', params: { stateabbr: stateAbbr, reelid: reelId, citynumber: cityNumber, imageid: imageId, accountid:this._appState.get('accountId') } })
      .pipe(
        map((image: Blob) => {
          const url = URL.createObjectURL(image);

          return url;
        })
      ).toPromise();
  }
}
