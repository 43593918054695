<div id="container-login">
  <h2>Log in</h2>

  <div class="lds-ring" aria-label="working..." *ngIf="isLoading"><div></div><div></div><div></div><div></div></div>

  <form #loginForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
    <div class="alert alert-danger" role="alert" *ngIf="hasError">
      <p>{{ errorText }}</p>
    </div>

    <fieldset>
      <div class="form-group">
        <label for="input-username">Username</label>
        <input type="text" id="input-username" name="username" value="" placeholder="Username" required [(ngModel)]="model.username">
      </div>
      <div class="form-group">
        <label for="input-password">Password</label>
        <input type="password" id="input-password" name="password" value="" placeholder="Password" required [(ngModel)]="model.password">
      </div>
    </fieldset>

    <div class="container-actions">
      <button type="submit" class="btn btn-submit btn-primary" *ngIf="!hasSubmitted">Log in</button>
      <button type="button" class="btn btn-primary btn-working" *ngIf="hasSubmitted"><div class="lds-dual-ring"></div></button>
    </div>
  </form>
</div>
