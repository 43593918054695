import { Injectable } from '@angular/core';
import { AppState } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class VolumesService {
  private _url: string = '/svc/sanborn/volumes';

  constructor (
    private _appState: AppState,
    private _http: HttpClient,
  ) {}

  public get (dateId: string): Promise<object[]> {
    const volumes = this._appState.get('volumes') || {};

    if (volumes[dateId]) {
      return of(volumes[dateId]).toPromise();
    }

    return this._http.get(this._url, { params: { accountid: this._appState.get('accountId'), id: dateId }})
      .pipe(
        map ((response) => {

          if (!volumes[dateId] && response['SanbornVolumesResponse']
            && response['SanbornVolumesResponse']['SanbornVolumeResponse']) {

            volumes[dateId] = Array.isArray(response['SanbornVolumesResponse']['SanbornVolumeResponse']) ?
                response['SanbornVolumesResponse']['SanbornVolumeResponse'] :
                [ response['SanbornVolumesResponse']['SanbornVolumeResponse'] ];

            this._appState.set({ volumes });
          }

          return response;
        }),
        switchMap ((response: object) => {
          let volumesDataArray = (response['SanbornVolumesResponse'] && response['SanbornVolumesResponse']['SanbornVolumeResponse'])
            ? response['SanbornVolumesResponse']['SanbornVolumeResponse']
            : [];

          if (!Array.isArray(volumesDataArray)) {
              volumesDataArray = [ volumesDataArray ];
          }

          return of(volumesDataArray);
        })
      )
      .toPromise();
  }
}
