import { Component, OnDestroy, OnInit } from '@angular/core';
import text from '../../assets/data/text.json'
import { Subscription } from 'rxjs';
import { AppState } from '../app.service';
import { SeoService, SessionService } from '../_services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';

@Component({
  selector: 'pq-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit, OnDestroy {

  public submitted: boolean = false;
  public currentError: string = '';

  public errorMsg: object = {
    incorrect: text.barcode.incorrect,
    missing: text.barcode.missing,
    issue: text.barcode.issue
  };

  private _subscription: Subscription;
  private _groupId: string;
  private _accountId: string;
  private _redirectLocation: string = '';
  private _defaultPath: string[] = ['/', 'about'];

  constructor (
    private _appState: AppState,
    private _seoSvc: SeoService,
    private _sessionSvc: SessionService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {

  }

  public ngOnInit (): void {
    this._subscription = this._route.queryParams.subscribe((queryParams: Params) => {
      this._accountId = queryParams['accountId'] || queryParams['accountid'];
      this._redirectLocation = queryParams['location'];

      if (this._accountId) {
        this._groupId = queryParams['groupId'] || queryParams['groupid'];
        this._seoSvc.setTitle('Barcode');
      } else {
        this._router.navigate(this._defaultPath);
      }

    });
  }

  public ngOnDestroy (): void {
    this._subscription.unsubscribe();
  }

  public onSubmitBarcode (e: Event, formData: object, isValid: boolean): void {
    e.preventDefault();

    this.submitted = true;
    this.currentError = '';

    if (isValid && formData['barcode']) {
      // reset state to default unauthenticated state
      this._resetLocalSessionData();

      const request = this._sessionSvc.doLogIn(formData).toPromise();

      request.then((response: object) => {
        this._handleResponse(response);
      });

      request.catch((err: any) => {
        this._handleFailure(err);
      });
    } else {
      this._showError(this.errorMsg['missing']);
    }
  }

  private _handleResponse (session: object): void {

    if (session['sessionRoles'] === 'authenticated') {
      const sessionObject = {
        creationDate: new Date(),
        sessionId: session['sessionId']
      };
      let location = this._defaultPath;

      this.currentError = '';
      sessionStorage.setItem('sessionObject', JSON.stringify(sessionObject));
      Cookie.set('session', sessionObject['sessionId'], null, '/');

      if (this._redirectLocation) {
        location = this._redirectLocation.split('/').filter((segment) => {
          return segment !== '';
        });

      }
      this._router.navigate(location);

    } else {
      this._showError(this.errorMsg['incorrect']);
    }
  }

  public groupId (): string {
    return this._groupId;
  }

  public accountId (): string {
    return this._accountId;
  }

  private _handleFailure (err: any): void {
    this._showError(this.errorMsg['issue']);
  }

  private _showError (msg: string): void {
    this.submitted = false;
    this.currentError = msg;
  }

  private _resetLocalSessionData (): void {
    sessionStorage.clear();
    Cookie.delete('session');
    Cookie.delete('accountId');
    this._appState.clear();
  }
}
