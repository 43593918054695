import { Component, OnInit } from '@angular/core';
import { AppState } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../_services';
import { BrandingInterface } from '../_utilities/branding.interface';

@Component({
  selector: 'pq-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./_app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  public browseMapsCommands: string[];
  public browseMapsCommandsLabel: string;
  public browseMapsQueryParams: object;
  public isAuthenticated: boolean =  this._appState.get('isAuthenticated');
  public isMenuOpen: boolean = false;
  public isWorking: boolean = false;
  public hasError: boolean = false;
  public branding: BrandingInterface = {
    displayBanner: false
  };

  constructor (
    private _appState: AppState,
    private _activatedRoute: ActivatedRoute,
    private _sessionSvc: SessionService,
    private _router: Router,
  ) {}

  public ngOnInit (): void {
    this.isWorking = false;
    this.hasError = false;

    this._appState.stateStatus.subscribe((state: object) => {
      if (typeof state['isAuthenticated'] !== 'undefined') {
        if (state['isAuthenticated']) {
          this.isAuthenticated =  true;
          this.browseMapsCommands = Array.isArray(this._appState.get('mapResultsLinkCommands')) ? this._appState.get('mapResultsLinkCommands') : undefined;
          this.browseMapsQueryParams = this._appState.get('mapResultsLinkQueryParams') || {};
          this.browseMapsCommandsLabel = (this.browseMapsCommands &&
            this.browseMapsCommands[this.browseMapsCommands.length - 1] === 'results') ?
            'Thumbnail grid' :
            'Map display';
        } else {
          this.isAuthenticated = false;
          this.browseMapsCommands = undefined;
        }

        if (typeof state['branding'] !== 'undefined') {
          console.log(state['branding']['brandingBanner'])
          if (state['branding']['brandingBanner']) {
            this.branding = state['branding']['brandingBanner'];
            this.branding.displayBanner = true;
          }
        }
      }
    });
  }

  public handleMobileMenuToggle (e: Event): void {
    if (this.isMenuOpen) {
      this.isMenuOpen = false;
    } else {
      this.isMenuOpen = true;
    }
  }

  public handleLogOutRequest (): void {
    console.log("logout method starts here")
    if (!this.isWorking) {
      this.isWorking = true;
      this.hasError = false;
      const request = this._sessionSvc.logout().toPromise();

      request.then((resp: object) => {
        this.isWorking = false;
        this.hasError = resp['success'];

        if (!this.hasError) {
          this.isAuthenticated = false;
          this._router.navigate(['/', 'login'], { replaceUrl: true });
        }
      });

      request.catch((err) => {
        this.isWorking = false;
        this.hasError = true;
      });

    }
  }
}
