import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate } from '@angular/router';
import { SessionService } from '../_services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppState } from '../app.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor (
    private _router: Router,
    private _sessionSvc: SessionService,
    private _appState: AppState
  ) {}

  public canActivate (route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot): Observable<boolean>  | Promise<boolean> | boolean  {
    return this._sessionSvc.isAuthenticated().pipe(
      map((state: boolean) => {
        this._appState.set({ isAuthenticated: state });

        if (!state) {
          this._router.navigate(['/', 'login'], { replaceUrl: true, queryParams: { location: routeState.url.split('?')[0] } });
        }

        return state;
      }),
      catchError((err) => {
        this._router.navigate(['/', 'login']);
        return of(false);
      })
    );
  }
}
